import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { v4 } from 'uuid'
import {
  FaInstagram,
  FaTwitter,
  FaFacebookSquare,
  FaWhatsapp,
  FaLinkedin,
} from 'react-icons/fa'
import { IoIosArrowRoundForward } from 'react-icons/io'
import Logo from '../Logo/Logo'
import './Footer.scss'

import { socialLinks } from '../../pages/contact'

const { getPageSlug } = require('../../utils')

const FooterMenu = ({ items }) => (
  <ul>
    {items.map(item => (
      <li key={v4()}>
        <Link
          to={getPageSlug(item)}
          dangerouslySetInnerHTML={{
            __html: item.title,
          }}
        />
      </li>
    ))}
  </ul>
)

const SplitMenus = ({ items }) => {
  const count = items.length
  const menus = [items.slice(0, count / 2), items.slice(count / 2 + 1, count)]
  return (
    <div className="columns">
      {menus.map(menu => (
        <div className="column split-menu" key={v4()}>
          <FooterMenu items={menu} />
        </div>
      ))}
    </div>
  )
}

class Footer extends React.Component {
  render() {
    return (
      <StaticQuery
        query={query}
        render={data => {
          const menus = {
            dhdesigns: data.allWordpressWpApiMenusMenusItems.edges.find(
              menu => menu.node.slug === 'footer-dh-designs'
            ).node,
            services: data.allWordpressWpApiMenusMenusItems.edges.find(
              menu => menu.node.slug === 'footer-services'
            ).node,
            locations: data.allWordpressWpApiMenusMenusItems.edges.find(
              menu => menu.node.slug === 'footer-locations'
            ).node,
          }
          return (
            <div className={`footer-wrapper ${this.props.classes}`}>
              <div className="footer-top">
                <div className="columns">
                  <div className="column is-4">
                    <p className="title start-a-project has-text-centered-mobile">
                      <Link to="/contact">
                        Start a project 
                        {' '}
                        <IoIosArrowRoundForward />
                      </Link>
                    </p>
                    <div className="social-icons-wrapper">
                      <div className="social-icons">
                        <a
                          href={socialLinks.instagram}
                          target="_blank"
                          rel="noopener"
                          className="social-icon instagram"
                        >
                          <FaInstagram />
                        </a>
                        <a
                          href={socialLinks.linkedin}
                          target="_blank"
                          rel="noopener"
                          className="social-icon linkedin"
                        >
                          <FaLinkedin />
                        </a>
                        <a
                          href={socialLinks.twitter}
                          target="_blank"
                          rel="noopener"
                          className="social-icon twitter"
                        >
                          <FaTwitter />
                        </a>
                        <a
                          href={socialLinks.facebook}
                          target="_blank"
                          rel="noopener"
                          className="social-icon facebook"
                        >
                          <FaFacebookSquare />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="column is-8">
                    <div className="columns">
                      <div className="column" />
                      <div className="column">
                        <h4 className="subtitle">DH Designs</h4>
                        <FooterMenu items={menus.dhdesigns.items} />
                      </div>
                      <div className="column">
                        <h4 className="subtitle">Services</h4>
                        <FooterMenu items={menus.services.items} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bottom">
                <div className="columns is-vcentered has-text-centered-mobile">
                  <div className="column">
                    <Logo isFooter />
                  </div>
                  <div className="column has-text-right has-text-centered-mobile">
                    <p>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                      {' '}
|&nbsp;
                      <Link to="/terms-of-service">Terms</Link>
                    </p>
                    <p>
                      &copy; Copyright&nbsp;
                      {new Date().getFullYear()}
                      {' '}
DH Designs
                    </p>
                  </div>
                </div>
              </div>
              <div className="coloured-bar">
                <div className="bar" />
                <div className="bar" />
                <div className="bar" />
                <div className="bar" />
                <div className="bar" />
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export default Footer

const query = graphql`
  query FooterMenu {
    allWordpressWpApiMenusMenusItems {
      edges {
        node {
          name
          slug
          count
          items {
            order
            wordpress_id
            title
            object_slug
            classes
            url
          }
        }
      }
    }
  }
`
