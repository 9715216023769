module.exports = {
  getPageSlug: page => {
    if (page.url) {
      return page.url.replace('https://admin.dhdesigns.uk', '')
    }

    if (page.parent_element) {
      return `/${page.parent_element.slug}/${page.slug}`
    }
    return `/${page.slug}`
  },
}
