import React from 'react'
import { Link } from 'gatsby'
import './logo.scss'

const Logo = ({ mobileMenuOpen, navbarColor, isFooter }) => {
  if (isFooter) {
    return (
      <h5
        className={`logo ${
          navbarColor ? `navbar-${navbarColor}` : ''
        } is-footer ${mobileMenuOpen ? 'active' : ''}`}
      >
        <Link to="/">
          <span className="top" />
          <span className="dh">DH</span>
          Designs
          <span className="bottom" />
        </Link>
      </h5>
    )
  }
  return (
    <h1
      className={`logo ${navbarColor ? `navbar-${navbarColor}` : ''} ${
        mobileMenuOpen ? 'active' : ''
      }`}
    >
      <Link to="/">
        <span className="top" />
        <span className="dh">DH</span>
        Designs
        <span className="extra">
          {' '}
          - Web & Mobile App Developer based in Wisbech, Cambridgeshire
        </span>
        <span className="bottom" />
      </Link>
    </h1>
  )
}

export default Logo
