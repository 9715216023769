import React from 'react'
import { Link } from 'gatsby'
import { v4 } from 'uuid'
import Logo from '../Logo/Logo'
import './Navbar.scss'

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.menuToggle = this.menuToggle.bind(this)
  }

  menuToggle(e) {
    console.log(e)
    e.preventDefault()

    const updatedState = !this.props.menuOpen
    this.props.onMenuStatusChange(updatedState)
  }

  render() {
    return (
      <nav className={`site-navbar ${this.props.color}`}>
        <div className="logo">
          <Logo
            mobileMenuOpen={this.props.menuOpen}
            navbarColor={this.props.color}
          />
        </div>
        <div className="burger-wrapper">
          <button
            onClick={e => this.menuToggle(e)}
            className={`hamburger hamburger--squeeze ${
              this.props.menuOpen ? 'is-active' : ''
            }`}
            type="button"
            aria-label="Menu"
            aria-controls="navigation"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
      </nav>
    )
  }
}

export default Navbar
