import React from 'react'
import { Link, StaticQuery, graphql, navigate } from 'gatsby'
import * as EmailValidator from 'email-validator'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import {
  FiMail,
  FiPhoneCall,
  FiFacebook,
  FiInstagram,
  FiTwitter,
  FiLinkedin,
} from 'react-icons/fi'
import { IoLogoWhatsapp } from 'react-icons/io'
import '../styles/pages/contact.scss'

const seo = {
  title: 'Contact Us',
  description:
    'Contact us today to discuss your online presence and discover ways to boost your business.',
}

export const socialLinks = {
  phone: 'Tel: +447597033790',
  facebook: 'https://www.facebook.com/dhdesignsuk/',
  instagram: 'https://www.instagram.com/dhdesigns_uk/',
  linkedin: 'https://www.linkedin.com/company/dhdesigns-uk/',
  twitter: 'https://twitter.com/dhdesigns_uk',
  whatsapp: 'https://wa.me/447597033790',
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { submitError: null }
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({ [e.target.name]: value })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({
      submitError: null,
      invalidEmail: false,
    })

    if (
      this.state.name &&
      this.state.email &&
      this.state.message &&
      EmailValidator.validate(this.state.email)
    ) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact',
          ...this.state,
        }),
      })
        .then(() => {
          navigate('/thanks')
        })
        .catch(error => alert(error))
    } else {
      this.setState({
        submitError: 'Please make sure all required fields are filled in.',
        invalidEmail: !EmailValidator.validate(this.state.email),
      })
    }
  }

  drawContactForm() {
    return (
      <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>

        <div className="field">
          <div className="control">
            <input
              className={`input is-medium ${
                this.state.submitError && !this.state.name ? 'is-danger' : ''
              }`}
              type="text"
              name="name"
              onChange={this.handleChange}
              placeholder="Your Name*"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <input
              className="input is-medium"
              type="text"
              name="company"
              onChange={this.handleChange}
              placeholder="Your Company"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <input
              className={`input is-medium ${
                this.state.submitError &&
                (!this.state.email || this.state.invalidEmail)
                  ? 'is-danger'
                  : ''
              }`}
              type="text"
              name="email"
              onChange={this.handleChange}
              placeholder="Your Email*"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <input
              className="input is-medium"
              type="text"
              name="telephone"
              onChange={this.handleChange}
              placeholder="Telephone"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <textarea
              className={`textarea is-medium ${
                this.state.submitError && !this.state.message ? 'is-danger' : ''
              }`}
              type="text"
              name="message"
              rows="8"
              onChange={this.handleChange}
              placeholder="Your Message*"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <label className="checkbox">
              <input
                type="checkbox"
                onChange={this.handleChange}
                name="agreement"
              />
              <span className="is-size-6">
                I acknowledge and agree to DH Design's{' '}
                <Link to="/privacy-policy">privacy policy</Link>
              </span>
            </label>
          </div>
        </div>
        <p>
          <button
            disabled={this.state.agreement ? '' : 'disabled'}
            className={`button is-outlined is-large is-yellow is-yellow-bg is-dark-text ${
              this.state.agreement ? '' : 'disabled'
            }`}
            type="submit"
          >
            <span>Send</span>
          </button>
        </p>
        {this.state.submitError && (
          <div className="form-error notification is-danger">
            {this.state.submitError}
          </div>
        )}
      </form>
    )
  }

  render() {
    return (
      <Layout color="dark">
        <SEO title={seo.title} description={seo.description} />
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="page-intro has-text-centered">
                  <h1 className="subtitle is-size-2 is-size-3-mobile">
                    Contact Us
                  </h1>
                  <p className="is-size-4 is-size-5-mobile">
                    We'll be glad to help
                  </p>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-5 is-offset-1 contact-info">
                <h3 className="title is-size-4">Contact Details</h3>
                <p>
                  <FiMail />
                  <a href="mailto:contact@dhdesigns.uk">contact@dhdesigns.uk</a>
                </p>
                <p>
                  <IoLogoWhatsapp />
                  <a href={socialLinks.whatsapp} target="_blank" rel="noopener">
                    WhatsApp
                  </a>
                </p>
                <p>
                  <FiPhoneCall />

                  <a href={socialLinks.phone}>0759 7033 790</a>
                </p>

                <div className="social-media">
                  <h3 className="title is-size-4">Follow us</h3>

                  <p>
                    <FiInstagram />
                    <a
                      href={socialLinks.instagram}
                      target="_blank"
                      rel="noopener"
                    >
                      Instagram
                    </a>
                  </p>
                  <p>
                    <FiLinkedin />
                    <a
                      href={socialLinks.linkedin}
                      target="_blank"
                      rel="noopener"
                    >
                      LinkedIn
                    </a>
                  </p>
                  <p>
                    <FiFacebook />
                    <a
                      href={socialLinks.facebook}
                      target="_blank"
                      rel="noopener"
                    >
                      Facebook
                    </a>
                  </p>
                  <p>
                    <FiTwitter />
                    <a href={socialLinks.twitter} target="_blank">
                      Twitter
                    </a>
                  </p>
                </div>
              </div>
              <div className="column is-5">
                <h3 className="title is-size-4">Email Directly</h3>
                {this.drawContactForm()}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ContactUsPage
