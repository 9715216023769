import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import './Hero.scss'

const words = [
  'Artistic,Amazing',
  'Bespoke,Beautiful',
  'Colourful,Creative',
  'Design,Dedicated',
  'Easy,Elegant',
  'Fantastic,Flair',
  'Genius,Gallant',
  'Happy,Honest',
  'Inspired,Innovative',
  'Jazzy,Joyful',
  'Knowledge,Knowhow',
  'Love,Launch',
  'Make,Marvel',
  'Nice,Nifty',
  'Outstanding,Original',
  'Perfect,Poised',
  'Quick,Quirky',
  'Reliable,Rational',
  'Smart,Sophisticated',
  'Triumphant,Timely',
  'Uniform,Unique',
  'Value,Versatility',
]

class Hero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      intervalId: null,
      wordsIndex: 0,
      previousWords: null,
      switching: false,
      switched: false,
      words: words[0].split(','),
    }
    this.drawText = this.drawText.bind(this)
    this.setupWordSwitcher = this.setupWordSwitcher.bind(this)
    this.timer = this.timer.bind(this)
  }

  componentDidMount() {
    const intervalId = setInterval(this.timer, 2500)
    this.setState({ intervalId })
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  timer() {
    const next =
      this.state.wordsIndex === words.length - 1 ? 0 : this.state.wordsIndex + 1
    this.setState({
      wordsIndex: next,
      previousWords: this.state.words,
      switching: true,
      switched: false,
      words: words[next].split(','),
    })

    setTimeout(() => {
      this.setState({
        switching: false,
        switched: true,
      })
    }, 500)
  }

  drawBackgroundImage(image, type) {
    if (type === 'fluid') {
      return <Img fluid={this.props.bgImage} alt={this.props.imageAlt} />
    }
    if (type === 'fixed') {
      return <Img fixed={this.props.bgImage} alt={this.props.imageAlt} />
    }
    return <img src={this.props.bgImage} alt={this.props.imageAlt} />
  }

  drawText(props) {
    if (props.title) {
      return (
        <div className="hero-content light-text">
          {props.subtitleTop && (
            <h2 className="subtitle is-hidden-mobile">{props.subtitleTop}</h2>
          )}
          <h1
            className={`title no-transform ${
              props.subtitleBottom ? '' : 'is-marginless'
            }`}
            dangerouslySetInnerHTML={{
              __html: props.title,
            }}
          />
          {props.subtitleBottom && (
            <h4 className="subtitle reversed is-hidden-mobile">
              {props.subtitleBottom}
            </h4>
          )}
          {props.url && (
            <div className="visit-website-button">
              <a
                className="button is-outlined is-large is-yellow"
                target="_blank"
                href={props.url}
              >
                <span>Visit Website</span>
              </a>
            </div>
          )}
        </div>
      )
    }
    return (
      <div className="hero-content light-text">
        <h3 className="subtitle is-hidden-mobile">DH Designs</h3>
        <h2
          className={`title text-switcher no-transform ${
            this.state.switching ? 'switching' : ''
          } ${this.state.switched ? 'switched' : ''}`}
        >
          <div className="top-line current">
            {`${this.state.words[0]} & ${this.state.words[1]}`}
          </div>
          {this.state.previousWords && (
            <div className="top-line previous">
              {`${this.state.previousWords[0]} & ${
                this.state.previousWords[1]
              }`}
            </div>
          )}
          Digital Experiences
        </h2>
        <h4 className="subtitle reversed is-hidden-mobile">
          Web & Mobile App Developer
        </h4>
      </div>
    )
  }

  setupWordSwitcher() {
    const index = 0
    // this.setState(state => ({
    //   words: words[index].split(','),
    // }))
    // if (index === words.length) {
    //   index = 0
    // } else {
    //   index++
    // }
  }

  render() {
    this.setupWordSwitcher()
    return (
      <div
        className={`hero-wrapper ${
          this.props.bgOverlay ? 'has-bg-overlay' : ''
        } ${this.props.noMargin ? 'no-margin' : ''}`}
      >
        {this.drawBackgroundImage(this.props.bgImage, this.props.type)}

        {this.drawText(this.props)}
        {this.props.scrollDown && <div className="icon-scroll" />}
      </div>
    )
  }
}

export default Hero
