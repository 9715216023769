import React from 'react'
import { Link } from 'gatsby'

import './ContactButton.scss'

class ContactButton extends React.Component {
  render() {
    return (
      <Link to="/contact" className="contact-button is-hidden-mobile">
        Contact Us
      </Link>
    )
  }
}

export default ContactButton
