import React from 'react'
import { Link } from 'gatsby'
import { v4 } from 'uuid'

import './OverlayMenu.scss'

export const links = [
  { label: 'Home', to: '/' },
  { label: 'Projects', to: '/projects' },
  { label: 'Services', to: '/services' },
  { label: 'About', to: '/about' },
  { label: 'Blog', to: '/blog' },
  { label: 'Contact', to: '/contact' },
]

class OverlayMenu extends React.Component {
  render() {
    return (
      <div className={`${this.props.visible ? 'visible' : ''} mobile-menu`}>
        <ul className="menu-links has-text-centered">
          {links.map(l => (
            <li key={v4()}>
              <Link to={l.to}>{l.label}</Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default OverlayMenu
